import React from "react"
import { Flex, Text } from "@chakra-ui/core"

export default () => (
  <Flex
    backgroundImage="linear-gradient(43deg, #171A3D 25%, #4B3870 100%)"
    color="white"
    px={4}
    py={2}
    justifyContent="center"
    p={[2, 6, "32px", "64px"]}
  >
    <Text maxWidth="1200px" fontWeight="bold">
      &copy; Allolytics 2019
    </Text>
  </Flex>
)
