import React from "react"
import { graphql } from "gatsby"
import { Flex, Text } from "@chakra-ui/core"
import HomeExplainer from "../components/HomeExplainer"
import DeveloperFocused from "../components/DeveloperFocused"
import Hero from "../components/Hero"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import Layout from "../components/Layout"

const P = ({ children }) => (
  <Text mb={6} as="p">
    {children}
  </Text>
)

const IndexPage = ({ data }) => {
  console.log({ data })
  return (
    <Layout>
      <Nav />
      <Flex
        alignItems="justify-content"
        flexDirection="column"
        alignItems="center"
        padding={2}
        color="gray.50"
        backgroundImage="linear-gradient(43deg, #171A3D 25%, #4B3870 100%)"
      >
        <Hero image={data.example.childImageSharp.fluid.src} />
        <HomeExplainer
          title="Simple setup"
          image={data.example.childImageSharp.fluid.src}
        >
          <P>
            If you know React, you know how to implement an A/B test with
            <b> Allolytics</b>.
          </P>
          <P>
            Use hooks, props, and components as you do every day to configure
            your test directly in your code. Our lightweight Allolytics library
            will collect your data as soon as the test is deployed
          </P>
        </HomeExplainer>
        <HomeExplainer
          title="Definitive analysis"
          image={data.exampleWithEvents.childImageSharp.fluid.src}
          reverse
        >
          <P>
            Don't waste developer time revising mathematics, Allolytics alerts
            you when a <em>statistically significant</em> amount of data has
            been collected, and presents the results in a beautiful and easy to
            comprehend way.
          </P>
          <P> No second guessing, and no need to bother your data teams.</P>
        </HomeExplainer>
        <HomeExplainer title="Robust control">
          <P>
            Make multivariate testing part of your product development process.
            No lazy loaded scripts, no DOM modification; full control, with
            tests reviewed and tested in your CI/CD process.
          </P>
        </HomeExplainer>
        <HomeExplainer title="Clear results" reverse>
          <P>
            Allolytics dashboards are tailored to teams investing in variant
            testing. Get macro insight into how combinations combinations of
            components affect time on site, page views, tracked events, and more
            (without getting lost).
          </P>
        </HomeExplainer>
        <DeveloperFocused />
      </Flex>
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    example: file(relativePath: { eq: "example.png" }) {
      childImageSharp {
        fluid(maxWidth: 2040, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    exampleWithEvents: file(relativePath: { eq: "example-with-events.png" }) {
      childImageSharp {
        fluid(maxWidth: 2040, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
