import React from "react"
import { Flex, Box, Text } from "@chakra-ui/core"

const HomeExplainer = ({
  title,
  body,
  image = null,
  reverse = null,
  children,
}) => (
  <Flex
    direction={["column-reverse", reverse ? "row-reverse" : "row"]}
    maxW="1200px"
    rounded="md"
    m={[2, 6]}
    p={[2, 6]}
    bg="white"
    color="gray.800"
  >
    <Box p={[2, 6]}>
      <Text fontSize="3xl" as="h2">
        {title}
      </Text>
      <Box as="hr" bg="pink.300" height={"1px"} maxW={"80px"} my={6} />
      <Text lineHeight="tall" fontSize={["md", "xl"]} as="p" mb={[2, null]}>
        {children}
      </Text>
    </Box>
    {image && (
      <>
        <Box>
          <img src={image} />
        </Box>
      </>
    )}
  </Flex>
)

export default HomeExplainer
