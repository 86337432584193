import React from "react"
import { Box } from "@chakra-ui/core"

export default () => (
  <Box
    as="hr"
    bg="pink.300"
    height={"1px"}
    minW={"80px"}
    maxW={"80px"}
    my={6}
  />
)
