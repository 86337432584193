import React from "react"
import { Flex, Box, Text } from "@chakra-ui/core"

const NavItem = props => (
  <Text {...props} p={3}>
    {props.children}
  </Text>
)

export default () => (
  <Flex
    bg="purple.800"
    color="white"
    position="sticky"
    height={[45]}
    lineHeight={1}
    alignItems="center"
    width="100%"
    borderBottomWidth={2}
    borderBottomColor="purple.900"
    top={0}
  >
    <NavItem fontWeight="bold">Allolytics</NavItem>
    <NavItem>About</NavItem>
    <NavItem>Documentation</NavItem>
  </Flex>
)
