import React from "react"
import { Text, Box, Flex } from "@chakra-ui/core"

export default ({ image }) => (
  <Flex p={[2, 6]} flexDirection="column" alignItems="center">
    <Text fontWeight={"bold"} fontSize={["6xl"]} as="h1">
      Allolytics
    </Text>
    <Box
      as="hr"
      bg="pink.300"
      height={"1px"}
      minW={"80px"}
      maxW={"80px"}
      my={6}
    />
    <Text as="h2" textAlign="center" fontSize={["3xl"]}>
      Simple A/B testing for React Components
    </Text>
    <Box maxW="800px">
      <img src={image} />
    </Box>

    <Box
      as="hr"
      bg="pink.300"
      height={"1px"}
      minW={"80px"}
      maxW={"80px"}
      my={6}
    />
  </Flex>
)
