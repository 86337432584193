import React from "react"
import { Flex, Text } from "@chakra-ui/core"
import Divider from "./Divider"

export default () => (
  <>
    <Text as="h2" fontWeight="bold" fontSize={["4xl"]}>
      Developer Focused
    </Text>
    <Divider />
    <Text as="p" maxW="60rem" p={6}>
      We believe that A/B testing should be managed and configured in code. In
      the same way you've moved your infrastructure configuration to code, or
      your styles. Allolytics is the first A/B testing toolkit design for
      developers first.
    </Text>
    <Divider />
  </>
)
